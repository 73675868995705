document.addEventListener("DOMContentLoaded", () => {
  const $galleryLinks = Array.prototype.slice.call(document.querySelectorAll('.dropdown-item'), 0);
  const $galleryTargets = Array.prototype.slice.call(document.querySelectorAll('.gc'), 0);

  if ($galleryLinks.length > 0) {
    // Add a click event on each of them
    $galleryLinks.forEach( el => {
      el.addEventListener('click', () => {
        //remove active from all links and targets
        $galleryLinks.forEach( el => {
          el.classList.remove('is-active');
        });
        $galleryTargets.forEach( el => {
          el.classList.remove('is-active');
        });
        // Get the target from the "data-target" attribute
        const target = el.dataset.target;
        const $target = document.getElementById(target);

        document.getElementById('gallery-menu').classList.remove('is-active');
        el.classList.add('is-active');
        $target.classList.add('is-active');
      });
    });
  }
});
